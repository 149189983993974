@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Cerebri', sans-serif;
}

@font-face {
    font-family: Cerebri;
    /* src:url("../public/font/Roboto/Roboto-Regular.ttf"); */
    src:url("../public/font/Cerebri/CerebriSansPro-Regular.ttf");
  }

  /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 49px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.css-1bdadyq:hover {
    background-color: transparent !important;
}

main{
  contain: inline-size;
}

a.link-main:hover .css-12w9als{
  margin-left: 5px;
  transition: margin-left .45s ease-in-out;
}

a.link-main:hover::after {
  content: '';
  position: absolute;
  width: 95%;
  height: 100%;
  background: #F6EB7A;
  right: 0;
  top: 0;
  border-radius: 25px 0px 0px 25px;
  z-index: 0;
}

a.link-main:hover{
  color: black !important;
}
a.link-main:hover .css-wx7wi4{
  color: #f5cf63;
  background: #062807;
  border-radius: 50%;
}

.ps-submenu-root .ps-menu-button:hover .css-wx7wi4{
  color: #131532;
  background: black;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.ps-submenu-root .ps-menu-button:hover .css-12w9als{
  color: black;
}
.ps-submenu-root .ps-menu-button.ps-open .css-wx7wi4{
  color: #131532;
  background: black;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.ps-submenu-root .ps-menu-button:hover::after {
  content: '';
  position: absolute;
  width: 95%;
  height: 50px;
  background: linear-gradient(45deg, #131532, #d0ff14);
  right: 0;
  top: 0;
  border-radius: 25px 0px 0px 25px;
  z-index: 0;
}

.ps-submenu-root .ps-menu-button.ps-open::after {
  content: '';
  position: absolute;
  width: 95%;
  height: 50px;
  background: linear-gradient(45deg, #131532, #d0ff14);
  right: 0;
  top: 0;
  border-radius: 25px 0px 0px 25px;
  z-index: 0;
}
.react-calendar__tile abbr {
background: blue!important;
border-radius: 50%!important;
}
.css-z5rm24 {
  background-color: #141414 !important;
}

.css-ra3y5v:hover {
  background-color: #ffffff3b !important;
}

.sticky{
  position: sticky !important;
}

/* header css start */

.css-ewdv3l a.link-main.active::after{
  content: '';
  position: absolute;
  width: 95%;
  height: 100%;
  /* background: #f5cf63; */
  background: #F6EB7A;
  right: 0;
  top: 0;
  border-radius: 25px 0px 0px 25px;
  z-index: 0;
}
.css-mf2zkt{
  z-index: 1;
}
.css-ewdv3l a.link-main{
  position: relative;
  display: block;
  color: #ffffff;
}
.css-1642kfo{
  color: #898989;
}
.ps-open .ps-menu-button.ps-open .css-wx7wi4{
  color: #131532;
  background: black;
  border-radius: 50%;
}
.ps-open .ps-menu-button.ps-open .css-12w9als{
  color: black;
  position: relative;
  z-index: 1;
}
.css-ewdv3l a.link-main.active .css-wx7wi4{
  color: #f5cf63;
  background: #062807;
  border-radius: 50%;
}
.css-ewdv3l a.link-main.active{
  color: black;
}
.css-1cuxlhl{
  position: relative;
  z-index: 1;
}
.css-12w9als{
  font-size: 16px;
  font-weight: 600;
  position: relative;
  z-index: 1;

}
.otp-inputs input{
  background: #f1f1f1;
  color: #837575;
  border: 1px solid #7E7777;
  border-radius: 0.5rem;
  width: 4rem !important;
  height: 3rem !important;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; */
}
.otp-inputs div:only-child{
  align-items: center;
  width: 75%;
  align-items: center;
  justify-content: space-around;
}
.otp-inputs input:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
/* header css end */
.card-dash:hover{
  /* background: linear-gradient(272deg, #a7e05d, #50aa53); */
}
.card-dash.active{
  background: linear-gradient(272deg, #a7e05d, #50aa53);
}

/* table css */
.table th:first-child {
  position: relative;
}
.tooltip {
  position: relative;
  display: inline-block;
 
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;

  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  display: none;
}
.tooltip .tooltiptext {
  bottom: 0;
  left: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: block;
  
}
.table :where( td) {
  white-space: normal!important;
  padding: 1rem;
  vertical-align: middle;
}
.BulletLabelText {
  font-size: small !important;
}
element.style {
  flex: 0 0 14.2857%;
  overflow: hidden;
  /* width: 5%; */
  /* padding: 11px; */
}
.react-calendar__tile .react-calendar__tile--active .react-calendar__tile--range .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd .react-calendar__tile--rangeBothEnds .react-calendar__month-view__days__day{
  text-decoration: none!important;
  border-radius: 50%!important;
  /* text-decoration-color: red; */
  color: rgb(0, 0, 0);
}
@media (max-width: 766px){
  .css-1wvake5.fixed{
    position: fixed !important;
  }
  .ps-toggled{
    display: block;
    transform: translateX(0);
  }
}

@media (min-width: 768px){
  .md\:sticky {
    position: sticky !important;
}
}

.tooltipcontact {
  position: relative;
  display: inline-block;
 
}

.tooltipcontact .tooltiptextcontact {
  visibility: hidden;
  width: 100%;

  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltipcontact .tooltiptextcontact {
  top: -5px;
  left: 105%;
}

.tooltipcontact:hover .tooltiptextcontact {
  visibility: visible;
  
}
/* pagination css*/
.pagination {
  padding-top: 1rem;
  text-align: center;
}

.pagination > ul {
  padding: 0;
  margin: 0;
}

.pagination li {
  display: inline-block;
}

.pagination li > a {
  padding: 0.5rem;
  margin: 1px;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d8eef5;
  border-radius: 2px;
  min-width: 1rem;
}

.pagination li > a:focus {
  outline: #9c31a3 solid 1px;
}
.pagination li.active{
  background-color: #F6EB7A;
}
.pagination li.active > a{
  background-color: #F6EB7A
}
.PhoneInputInput:focus{
  outline: 0 !important;
}
.PhoneInputInput:disabled{
  background-color: white;
}

/* graph hamburger button hide  */
.apexcharts-toolbar {
  display: none !important;
 
}

.jodit-toolbar-button__button {
  color: black !important;
}

.jodit-toolbar-editor-collection {
  background-color: #c5c5c5 !important;
}

.jodit-wysiwyg {
  padding: 1.5rem !important; 
}

.jodit-wysiwyg ol {
  padding-left: 1.5rem !important; 
 list-style-position: outside !important;
}

.jodit-wysiwyg ul {
  padding-left: 1.5rem !important; 
  list-style-position: outside !important;
  list-style: initial;
 }


 .jodit-wysiwyg li {
  padding-left: 0.5rem !important; 
  list-style-position: inside !important;
  list-style-position: outside !important;
 }

 .jodit-wysiwyg a {
  color: #277db6 !important;
  text-decoration: underline !important;
 }


 .chat-box ol,.chat-box ul {
  padding-left: 1.5rem !important; 
 list-style-position: outside !important;
}


.chat-box ul {
  padding-left: 1.5rem !important; 
  list-style-position: outside !important;
  list-style: initial;
 }

 .message-body ul ,.message-body ol{
  padding-left: 1.5rem !important; 
  list-style-position: outside !important;
  list-style: initial;
 }
 .message-body li{
  list-style-position: outside;
 }


 .chat-box li {
  padding-left:0.5rem !important; 
  list-style-position: outside !important;
 }
 .innerbubble li{
  list-style-position: outside !important;
 }


 .chat-box a {
  color: #277db6 !important;
  text-decoration: underline !important;
 }
 .chat-box img{
  width: 100%;
  border-radius: 10px;
 }
 .chat-box .modal-box{
  border: 10px solid black;
  padding: 10px;
  border-top: 60px solid black;
 }
 .innerbubble{
  white-space: normal;
  width: 80%;
  background: #e9e9e9;
  padding: 10px;
  border-radius: 10px;
 }

 .message-body a{
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 0;
    font-weight: 700;
    padding: 10px;
    left: 0;
    border-top: 1px solid #dbd3cd;
    color: #0062cc;
 }

 .message-body a::before{
  content: "";
  /* background-image:url("https://cdn-icons-png.flaticon.com/512/3405/3405255.png"); */
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 50;
  background-size: cover;
  margin-left: -21px;
  color: blue
 }
 


